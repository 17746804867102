.modal._sale .modal__wrap {
    background: none;
    padding: 0;
}

.modal._sale .modal__content {
    margin-top: auto;
    height: auto;
}

.shop__promocode-alert {
    position: fixed;
    left: 0;
    top: 44px;
    right: 0;
    z-index: 200;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: .3s;
}

.shop__promocode-alert._show {
    opacity: 1;
}

.shop__promocode-alert span {    
    height: 32px;
    background: #FFFFFF;
    border-radius: 50px;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #A7ABAC;
}

.shop__promocode {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 230;
    display: flex;
    align-items: flex-end;
    top: 0;
    transition: .3s;
    opacity: 0;
    pointer-events: none;
}

.shop__promocode__bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: rgba(0,0,0,.5);
}

.shop__promocode._active {
    opacity: 1;
    pointer-events: all;
}

.promocode {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
    padding: 44px 20px 20px;
    position: relative;
    max-width: 100%;
    z-index: 20;
}

.promocode .promocode__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
    /* max-width: 275px; */
}

.promocode .promocode__lead {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 24px;
}
.promocode .promocode__lead p {
    margin-bottom: 12px;
}
.promocode .promocode__lead p:last-child {
    margin-bottom: 0;
}

.promocode .promocode__value {
    height: 52px;
    border: 1px dashed #505759;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #505759;
    margin-bottom: 16px;
}

.promocode .promocode__info {
    font-weight: 400;
    font-size: 10px;
    line-height: 17px;
    color: #7F7E82;
}
.promocode .promocode__info a {
    color: #7F7E82;
}

.promocode__footer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 150;
    border-radius: 0px;
    background: #fff;
    padding: 44px 20px 0;
    margin-left: -20px;
    margin-right: -20px;
}

.shop__promocode-close {
    position: absolute;
    width: 27px;
    height: 27px;
    background: #F1F2F3;
    border-radius: 50%;
    right: 15px;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
}

@media screen and (min-width: 1024px) {

    .promocode {
        max-width: 991px;
        width: 100%;
        margin: 0 auto;
    }

    .promocode__footer {
        flex-direction: row;
        justify-content: center;
    }


    .promocode__footer .button {
        max-width: 282px;
    }

}



@media screen and (min-width: 1024px) {
    
    .modal._sale .modal__content {
        margin-top: 0;
    }

    .modal._sale .modal__wrap {
        align-items: center;
        justify-content: center;
    }

    .promocode {
        max-width: 588px;
        border-radius: 8px;
    }

    .promocode__footer .button {
        max-width: 100%;
    }
    
}