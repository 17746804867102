.modal._search .modal__wrap {
    padding-left: 0;
    padding-right: 0;
}

.modal._search .modal__header {
    padding-left: 20px;
    padding-right: 20px;
}

@media screen and (min-width: 1024px) {

    .modal._search .modal__wrap {
        max-width: 1200px;
        margin: 0 auto;
    }

}