.modal._default._sales {
    z-index: 800;
}

.modal._default._sales .modal__wrap {
    padding-bottom: 74px;
}

.sales__title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.37px;
}

.sales__list {
    margin: -6px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.sales__list-item {
    width: 50%;
    padding: 6px;
}

.sales .post._sale {
    width: 100%;
}

@media screen and (max-width: 1023px) {

    .sales__list-item:nth-child(6n + 1) .post._sale{
        /* height: 252px; */
        height: 68vw;
    }

    .sales__list-item:nth-child(6n + 2) .post._sale{
        /* height: 120px; */
        height: 36vw;
    }

    .sales__list-item:nth-child(6n + 3) .post._sale{
        /* height: 120px; */
        height: 36vw;
    }

    .sales__list-item:nth-child(6n + 4) .post._sale{
        /* height: 252px; */
        height: 68vw;
    }

    .sales__list-item:nth-child(6n + 4) {
        /* margin-top: -132px; */
        margin-top: -32vw;
    }

    .sales__list-item:nth-child(6n + 5) .post._sale{
        /* height: 120px; */
        height: 36vw;
    }

    .sales__list-item:nth-child(6n + 6) .post._sale{
        /* height: 120px; */
        height: 36vw;
    }

}

@media screen and (min-width: 1024px) {

    .sales__title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 30px;
        padding-left: 32px;
    }

    .sales__list {
        margin: 0;
        display: grid;
        gap: 24px;
        
    }

    .sales__list-item {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .sales .post._sale {
        aspect-ratio: 1.68;
        height: auto;
    }
    
}
@media screen and (min-width: 1024px) and (max-width: 1239px) {
    .sales__list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (min-width: 1240px) { 
    .sales__list {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media screen and (min-width: 1546px) and (max-width: 1850px) { 
    .sales__list {
        /* grid-template-columns: repeat(5, 1fr); */
    }
}
@media screen and (min-width: 1851px) and (max-width: 2155px) { 
    .sales__list {
        /* grid-template-columns: repeat(6, 1fr); */
    }
}
@media screen and (min-width: 2156px)  { 
    .sales__list {
        /* grid-template-columns: repeat(7, 1fr); */
    }
}