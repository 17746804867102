.page._home .page__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}

.page._home .page__select-city {
  margin-bottom: 12px;
}

.page._home .page__filter .page__title {
  margin-top: 0;
  padding-bottom: 24px;
}

.page._home .page__hero .page__title {
  display: none;
}

@media screen and (max-width: 1023px) {

  .page._home .page__filter .page__title {
    display: none;
  }

  .page._home .page__hero .page__title {
    display: block;
  } 

}

.page._home .page__select-city a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7f7e82 !important;
  text-decoration: none;
}

.page._home .page__loader {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.page._home .page__title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #000000;
}

.page._home .page__search {
  margin-bottom: 21px;
}

.page._home .page__category {
  margin-bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .page._home .page__category + .page__category {
    margin-top: 32px;
  }
}

.page._home .page__filter {
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.page__filter._mobile {
  display: none;
}

.category._default {
}

.category._default .category__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.category._default .category__link {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7f7e82 !important;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.category._default .category__link svg {
  position: relative;
  top: -2px;
}

.category._default .category__link:hover {
  color: #EF3124 !important;
}

.category._default .category__link:hover svg rect {
  fill: #EF3124;
}

.category._default .category__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

#allrecords .category._default .category__title a {
  text-decoration: none;
  color: #000000 !important;
}

.category._default .category__list {
  display: flex;
  gap: 11px;
  overflow: auto;
  margin: 0 -20px;
}

.category._default .category__list._vertical {
  overflow: visible;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
}

.category._default .category__list._vertical:before,
.category._default .category__list._vertical:after {
  display: none;
}

.category._default .category__list:before,
.category._default .category__list:after {
  content: "";
  width: 9px;
  min-width: 9px;
  height: 20px;
}

.category._default .category__list::-webkit-scrollbar {
  display: none;
}

.filter._category {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter._category:before {
  content: "";
  min-width: 12px;
  width: 12px;
  height: 20px;
}

.filter._category .filter__toggle {
  width: 36px;
  min-width: 36px;
  height: 36px;
  background: #f1f2f3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.filter._category .filter__list {
  display: flex;
  gap: 8px;
  overflow: auto;
}

.filter._category .filter__list:after {
  content: "";
  width: 12px;
  min-width: 12px;
  height: 20px;
}

.filter._category .filter__list::-webkit-scrollbar {
  display: none;
}

.filter-list._desktop {
  display: none;
}

.page._home .page__full-search {
  display: none;
}

@media screen and (max-width: 1023px) {
  .page__filter._mobile {
    display: block;
  }

  .page__filter._desktop {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .page._home .category._default._partners .category__list {
    display: grid;

    grid-template-columns: repeat(4, 1fr);
  }

  .page._home .category._default._partners .category__list .post._shop {
    width: 100%;
  }

  .page._home
    .category._default._partners
    .category__list
    .post._shop
    .post__preview {
    height: 16vw;
    max-height: 205px;
  }

  .filter-list._desktop {
    display: block;
  }

  .page._home .page__hero {
    display: flex;
    justify-content: space-between;
  }

  .page._home .page__filter {
    margin-left: 0;
    margin-right: 0;
  }

  .page._home .page__search {
    width: 282px;
  }

  .filter._category .filter__toggle {
    display: inline-flex;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .filter._category {
    display: inline-block;
  }

  .filter._category .filter__list {
    display: inline;
    overflow: visible;
  }

  .filter._category .filter__list-item {
    display: inline-flex;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .page._home .page__filter {
    /* position: relative; */
    z-index: 20;
  }

  .filter-list._desktop {
    /* padding-right: 20px;
        overflow: auto;
        position: fixed;
        left: 0;
        width: 100%;
        max-width: 270px;
        top: 0;
        bottom: 0; */
    /* border-radius: 8px; */
    /* background: #fff; */
    /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05); */
    /* overflow: auto; */
    z-index: 30;
  }

  .filter-list._desktop .filter-list__title {
    margin-bottom: 24px;
    font-family: "Styrene B LC";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
  }

  .filter-list._desktop .filter-list__close {
    position: absolute;
    right: 52px;
    top: 30px;
  }

  .filter-list._desktop .filter-list__wrap {
    padding-top: 20px;
    /* overflow: auto; */
    /* max-height: 684px; */
    /* padding-right: 16px; */
  }

  .filter-list._desktop._show {
    display: block;
  }

  .filter-list._desktop .filter-list__close {
    display: none;
  }

  .filter-list._desktop .filter-list__title {
    display: none;
  }

  .filter-list._desktop .filter-list__wrap {
    /* max-height: 100vh; */
  }

  .filter._category {
    display: none;
  }

  .page._home {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    /* min-height: 100vh; */

    /* display: grid;
        grid-template-columns: 200px 1fr; */
  }

  .page._home .page__filter {
    width: 270px;
    min-width: 270px;

    max-height: calc(100vh - 110px);
    /* max-height: 100vh; */
    overflow: auto;
    position: sticky;
    top: 110px;
    padding-top: 0px;

    margin-bottom: 0;
    padding-right: 20px;
  }

  .page._home .page__hero .page__search {
    display: none;
  }

  .page._home .page__wrap {
    padding-top: 20px;
    padding-bottom: 20px;

    width: calc(100% - 270px);
    padding-left: 36px;
    /* width: 100%; */
  }

  .page._home .page__category:last-child {
    margin-bottom: 0;
  }

  .page._home .page__full-search {
    display: block;
    margin-bottom: 20px;
  }

  .category._default .category__list {
    gap: 24px;
    margin: 0 -33px;
  }

  .category._default._partners .category__list {
    flex-wrap: wrap;
    margin: 0;
  }

  .category._default._sale .category__list {
    margin: 0;
  }

  .category._default._sale .category__list:before,
  .category._default._sale .category__list:after {
    display: none;
  }

  .category._default._partners .category__list:before,
  .category._default._partners .category__list:after {
    display: none;
  }
}


.special {
  margin-bottom: 24px;
}

.special__title {
  margin-bottom: 12px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.special__preview {
  width: 100%;
  height: 190px;
  border-radius: 8px;
  background: #F1F2F3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 16px;
}

.special__preview img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.special__rating {
  position: absolute;
  left: 8px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 37px;
  background: #fff;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 6px;
}

.special__rating span {
  margin-top: 2px;
}

.special__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 30px;
}

.special__content .row {
  display: flex;
  justify-content: space-between;
}

.special__name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.special__price {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #EF3124;
}

.special__desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.special__total {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}