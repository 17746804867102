.post._shop {
    display: block;
    width: 152px;
    text-decoration: none;
}

.post._shop .post__preview {
    display: block;
    height: 152px;
    background-position: center top;
    background-size: cover;
    position: relative;
    border-radius: 8px;
    margin-bottom: 12px;
    background-color: #bbb;
    overflow: hidden;
}
.post__preview_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.post._shop .post__logo {
    position: absolute;
    left: 9px;
    top: 9px;
    background: transparent;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 1;
}
.post._shop .post__logo::before {
    content: "";
    position: absolute;
    top: 0.5px;
    left: 0.5px;
    right: 0.5px;
    bottom: 0.5px;
    background: #fff;
    z-index: 0;
    border-radius: 50%;
    overflow: hidden;

}

.post._shop .post__logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 1;
}

.post._shop .post__sale {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 51px;
    height: 22px;
    position: absolute;
    bottom: 9px;
    left: 9px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.post._shop .post__title {
    margin-bottom: 4px;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.post._shop .post__category {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #7F7E82;
    display: block;
}

/* <a href={link} className='post _shop'>
    <span className='post__preview' style={{ backgroundImage: 'url(' + preview + ')' }}>
        <span className='post__logo'>
            <img src={logo} />
        </span>
        <span className='post__sale'>
            {sale}
        </span>
    </span>
    <span className='post__title'>{title}</span>
    <span className='post__category'>{category}</span>
</a> */

.post._shop._search {
    width: 100%;
}

.post._shop._search .post__preview {
    height: 43vw;
}

.post._shop .post__shops {
    display: none;
}

@media screen and (min-width: 1024px) {

    .post._shop {
        /* width: 195px; */
    }

    .post._shop .post__preview {
        /* height: 195px; */
    }

    .post._shop .post__shops {
        position: absolute;
        display: inline-flex;
        padding: 4px 8px;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 8px;
        border-radius: 4px;
        background: #fff;
        right: 8px;
        top: 8px;
    }

    .post._shop._search .post__preview {
        height: 205px;
    }

}