.checkbox._filter .checkbox__input {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}

.checkbox._filter .checkbox__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #000000;
}

.checkbox._filter .checkbox__title {
    max-width: 200px;
}

.filter._list .filter__category {
    margin-bottom: 32px;
}

.filter._list .filter__category-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 28px;
}

.filter._list .filter__category-list {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.switche {
    display: block;
    width: 44px;
    height: 24px;
    background: #E8E8E8;
    border-radius: 74px;
    position: relative;
    transition: all .3s;
}

.switche__bull {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border-radius: 50%;
    transition: all .3s;
}

.checkbox._filter .checkbox__input:checked + .checkbox__wrap .switche {
    background: #505759;
}

.checkbox._filter .checkbox__input:checked + .checkbox__wrap .switche__bull {
    left: 22px;
}

.filter-reset {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #7F7E82;
    text-decoration: none;
}

@media screen and (min-width: 1024px) {
    
    .filter._list .filter__category-list {
        gap: 24px;
    }

}

.checkbox._filter .checkbox__check {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #c5c5c7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox._filter .checkbox__check svg {
    opacity: 0;
    transition: 0.2s;
}

.checkbox._filter .checkbox__input:checked + .checkbox__wrap .checkbox__check {
    background: #EF3124;
    border-color: #EF3124;
}

.checkbox._filter
    .checkbox__input:checked
    + .checkbox__wrap
    .checkbox__check
    svg {
    opacity: 1;
}