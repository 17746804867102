.modal._default {
    /* display: none; */
    opacity: 0;
    height: 0;
    overflow: hidden;
    pointer-events: none;

    position: fixed;
    /* padding-top: 10px; */
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
}

.modal._default._active {
    /* display: block; */
    opacity: 1;
    height: auto;
    overflow: visible;
    pointer-events: all;
}
.modal._default._active._catalog {
    top: 50px;
}

.modal._default .modal__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    z-index: 50;
    pointer-events: none;
}

.modal._default .modal__wrap {
    height: 100%;
    background: #fff;
    /* border-radius: 10px 10px 0 0; */
    position: relative;
    z-index: 100;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    /* pointer-events: none; */
}

.modal._default .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    gap: 12px;
}

.modal._default .modal__content {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    margin: 0 -20px;
    padding: 0 20px;
    pointer-events: all;
    /* height: calc(100% - 76px); */
}

.modal._default .modal__footer {
    border-top: 1px solid #E8E8E8;
    border-radius: 12px 12px 0px 0px;
    position: relative;
    z-index: 20;
    background: #fff;
    padding: 20px 20px 0;
    margin-left: -20px;
    margin-right: -20px;
}