.search {
    position: relative;
    width: 100%;
}

.search__input {
    width: 100%;
    border: none;
    background: #F1F2F3 url('https://cdn.podeli.ru/web-catalog/assets/img/icon-search.svg') no-repeat 8px center;
    border-radius: 8px;
    height: 36px;
    -webkit-appearance: none;
    padding-left: 33px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-right: 32px;
    padding-top: 3px;
}

.search__input::placeholder {
    padding-left: 2px;
}

.search__reset {
    position: absolute;
    right: 4px;
    top: 6px;
    width: 24px;
    height: 24px;
}

@media screen and (min-width: 1024px) {

    .search__input {
        height: 52px;
    }

    .search__reset {
        top: 14px;
    }

}