@import './normalize.css';
@import './fonts.css';


img {
    max-width: 100%;
    display: block;
}

.category__title a {
    color: inherit;
    text-decoration: none;
}

.page {
    padding: 20px 20px 54px;
    background: #fff;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

body {
    font-family: 'Styrene B LC';
}

body._hidden {
    overflow: hidden;
}

.button {
    font-family: 'Styrene B LC';
	outline: none;
	background: none;
	border: none;
	box-shadow: none;
	border-radius: 0;
	-webkit-appearance: none;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	vertical-align: top;
}

.button._default {
    width: 100%;
    padding: 19px 16px 16px;
    background: #000000;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF !important;
}

@media screen and (min-width: 1024px) {
    
    .button._default {
        padding: 18px 16px 17px;
    }

}

.button._default._gray {
    color: #000000 !important;
    background: #F1F2F3;
}

.button._default._red {
    color: #ffffff !important;
    background: #EF3124;
}

.button._default._white {
    color: #000000 !important;
    background: #Ffffff;
}

.button._sale {
    text-align: left;
    justify-content: flex-start;
    padding: 10px 32px 10px 10px;
    background: #F1F2F3 url('https://cdn.podeli.ru/web-catalog/assets/img/icon-chevron-right.svg') no-repeat 97% 12px;
    border-radius: 8px;
    gap: 8px;
}

.button._sale .button__icon {
    width: 20px;
    min-width: 20px;
}

.button._sale .button__title {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000 !important;
    margin-bottom: 2px;
}

.button._sale .button__description {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7F7E82 !important;
}

input {
    font-family: 'Styrene B LC';
    touch-action: manipulation;
    outline: none;
}

input:focus {
    outline: none;
}

.swiper .category__list-item {
    width: 342px;
}

.page__category._sales .category._default .category__slider {
    display: none;
}

@media screen and (min-width: 1024px) {

    .page__category._sales .category._default .category__list {
        display: none;
    }

    .page__category._sales .category._default .category__slider {
        display: block;
    }

}

.swiper-button-prev {

}

.category__slider {
    position: relative;
    /* overflow: hidden;
    padding: 0 30px; */
}

.category__slider .swiper-wrapper {
    /* overflow: hidden; */
    /* padding: 0 30px; */
}

.category__slider .swiper {
    /* overflow: visible; */
    /* margin: 0 -30px; */
}

.category__slider .category__slider-prev {
    position: absolute;
    left: -30px;
    top: 50%;
    margin-top: -12px;
    background: none;
    border: none;
    cursor: pointer;
}

.category__slider .category__slider-next {
    position: absolute;
    right: -30px;
    top: 50%;
    margin-top: -12px;
    background: none;
    border: none;
    cursor: pointer;
}

@media screen and (min-width: 1024px) {

    .category__slider._shadow-before:before {
        content: '';
        background: rgb(255,255,255);
        background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        width: 30px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 20;
    }

    .category__slider._shadow-after:after {
        content: '';
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        width: 30px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 20;
    }

}

.post .post__logo img {
    border-radius: 50%;
}