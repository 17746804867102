.page._inner {
    position: relative;
    padding: 0;
    /* padding: 10px 0 0; */
    z-index: 1200;
}

/* .page._inner .page__bg {
    position: absolute;
    left: 0px;
    top: 0;
    right: 0px;
    height: 50px;
    background: #000;
    z-index: 50;
} */

/* .page._inner .page__bg:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;
    right: 10px;
    bottom: 0;
    background: #A7ABAC;
    border-radius: 10px 10px 0 0;
} */

.page._inner .page__wrap {
    background: #fff;
    /* border-radius: 10px 10px 0 0; */
    position: relative;
    z-index: 100;
    padding: 24px 20px;
}

.page._inner .page__header-back {
    /* width: 40px;
    height: 40px; */
    border-radius: 50%;
    /* background-color: rgba(0,0,0,.5); */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 4px;
}
.page._inner._shop .page__header-back {
    background-color: transparent;
}

.page._inner._category .page__header-back {
    /* background-color: rgba(0,0,0,.1); */
}

.page._inner .page__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin-bottom: 18px;
}

.page._inner .page__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #E8E8E8;
    border-radius: 12px 12px 0px 0px;
    z-index: 20;
    background: #fff;
    padding: 20px 20px 20px;
}

@media screen and (min-width: 1024px) {

    .page._inner .page__header {
        position: relative;
    }
    
    .page._inner .page__header-back {
        position: absolute;
        left: 0;
        top: 18px;
    }

}