.results._category {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.results._category .category._default .category__list._vertical {
    gap: 0;
    margin: -10px -6px;
}

.results._category .category__list._vertical .category__list-item {
    width: 50%;
    padding: 10px 6px;
}

.results._category .post._shop {
    width: 100%;
}

.results._category .post._shop .post__preview {
    height: 162px;
}

.results__category._sales .category__slider {
    display: none;
}

@media screen and (min-width: 1024px) {

    .results__category._sales {
        /* display: none; */
    }

    .results__category._sales .category._default .category__list {
        display: none;
    }

    .results__category._sales .category__slider {
        display: block;
    }
    
    .results._category .category._default .category__list._vertical {
        margin: 0;
        gap: 36px 16px;
    }

    .results._category .category__list._vertical .category__list-item {
        width: 205px;
        padding: 0;
    }

    .results._category .post._shop .post__preview {
        height: 205px;
    }



}
.page._inner._category .results__category.second .category__title {
    padding-left: 0;
}